import React, { useState } from 'react';

import { Button, Row, Col } from 'antd'
import TopImageShow from '../../components/topImageShow/TopImageShow';
import './sponsor.less'


// 按钮行
function ToBeSponsor () {

  const [images, setImages] = useState({
    'index': 'https://images-1304504321.cos.ap-guangzhou.myqcloud.com/images/%E8%B5%9E%E5%8A%A9%E5%95%86/index.jpg'
  })

  const [partnerLogo, setPartnerLogo] = useState([
    'https://images-1304504321.cos.ap-guangzhou.myqcloud.com/images/%E8%B5%9E%E5%8A%A9%E5%95%86/%E5%90%88%E4%BD%9C%E4%BC%99%E4%BC%B4/p_1.jpeg',
    'https://images-1304504321.cos.ap-guangzhou.myqcloud.com/images/%E8%B5%9E%E5%8A%A9%E5%95%86/%E5%90%88%E4%BD%9C%E4%BC%99%E4%BC%B4/p_2.jpg',
    'https://images-1304504321.cos.ap-guangzhou.myqcloud.com/images/%E8%B5%9E%E5%8A%A9%E5%95%86/%E5%90%88%E4%BD%9C%E4%BC%99%E4%BC%B4/p_3.jpg',
    'https://images-1304504321.cos.ap-guangzhou.myqcloud.com/images/%E8%B5%9E%E5%8A%A9%E5%95%86/%E5%90%88%E4%BD%9C%E4%BC%99%E4%BC%B4/p_4.jpg',
    'https://images-1304504321.cos.ap-guangzhou.myqcloud.com/images/%E8%B5%9E%E5%8A%A9%E5%95%86/%E5%90%88%E4%BD%9C%E4%BC%99%E4%BC%B4/p_5.png',
    'https://images-1304504321.cos.ap-guangzhou.myqcloud.com/images/%E8%B5%9E%E5%8A%A9%E5%95%86/%E5%90%88%E4%BD%9C%E4%BC%99%E4%BC%B4/p_6.png',
    'https://images-1304504321.cos.ap-guangzhou.myqcloud.com/images/%E8%B5%9E%E5%8A%A9%E5%95%86/%E5%90%88%E4%BD%9C%E4%BC%99%E4%BC%B4/p_7.png',
    'https://images-1304504321.cos.ap-guangzhou.myqcloud.com/images/%E8%B5%9E%E5%8A%A9%E5%95%86/%E5%90%88%E4%BD%9C%E4%BC%99%E4%BC%B4/p_8.png',
    'https://images-1304504321.cos.ap-guangzhou.myqcloud.com/images/%E8%B5%9E%E5%8A%A9%E5%95%86/%E5%90%88%E4%BD%9C%E4%BC%99%E4%BC%B4/p_9.png',
    'https://images-1304504321.cos.ap-guangzhou.myqcloud.com/images/%E8%B5%9E%E5%8A%A9%E5%95%86/%E5%90%88%E4%BD%9C%E4%BC%99%E4%BC%B4/p_10.png',
    'https://images-1304504321.cos.ap-guangzhou.myqcloud.com/images/%E8%B5%9E%E5%8A%A9%E5%95%86/%E5%90%88%E4%BD%9C%E4%BC%99%E4%BC%B4/p_11.png',
  ])


  const [silverLogo, setSilverLogo] = useState([
    'https://images-1304504321.cos.ap-guangzhou.myqcloud.com/images/%E8%B5%9E%E5%8A%A9%E5%95%86/%E9%93%B6%E7%89%8C/s_1.png',
  ])

  const partner = {
    title: '成为合作伙伴',
    condition: '等效赞助金额1万元人民币以下',
    rewards: [{
      title: "企业品牌宣传",
      content: "车身侧面LOGO展示（6cm*12cm）\n 车队对外宣传手册中，产品、品牌 LOGO 展示 \n 车队相关公众号中LOGO展示 \n 车队对外宣讲PPT中品牌LOGO展示\n赞助商展板中品牌LOGO展示\n"
    }, {
      title: "社会宣传",
      content: "车队相关活动新闻稿中介绍"
    }, {
      title: "网络宣传",
      content: "车队网络平台赞助商页面中英文介绍并附上企业官网链接"
    }, {
      title: "推广回报",
      content: "企业将定期收到车队工作进度汇报邮件，开展技术及其他方面的友好交流 \n 使用车队平台发布招聘或活动信息\n赛季结束，企业将收到详细赞助反馈总结\n企业可与其他友队及赞助商建立良好关系 , 扩大影响力，提升业内品牌知名度"
    }]
  }

  const yinpai_sponsor = {
    title: '成为银牌赞助商',
    condition: '等效赞助金额1万元以上',
    rewards: [{
      title: "企业品牌宣传",
      content: "车身侧面LOGO展示（10cm*20cm）\n 车队对外宣传手册中，产品、品牌 LOGO 展示\n车队相关公众号中LOGO展示\n车队宣传视频等资料中产品、品牌LOGO展示\n车队对外宣讲PPT中品牌LOGO展示\n赞助商展板中品牌LOGO展示"
    }, {
      title: "社会宣传",
      content: "车队相关活动新闻稿中介绍"
    }, {
      title: "网络宣传",
      content: "车队网络平台赞助商页面中英文介绍并附上企业官网链接"
    }, {
      title: "推广回报",
      content: "企业可使用车队队名、LOGO、赛车外观、赛车名称等用于企业产品宣传\n企业将定期收到工作进度汇报邮件， 开展技术与运营方面的交流学习 \n使用车队平台发布招聘或活动信息\n赛季结束，企业将收到详细赞助反馈总结\n企业可与其他友队及赞助商建立良好关系 , 扩大影响力，提升业内品牌知名度"
    }]
  }

  const jinpai_sponsor = {
    title: '成为金牌赞助商',
    condition: '等效赞助5万元人民币以上',
    rewards: [{
      title: "企业品牌宣传",
      content: "车身侧面展示（15cm*25cm）\n 前翼展示（10cm*20cm）\n车队对外宣传手册中，产品、品牌 LOGO 展示\n车队相关公众号中LOGO展示\n车队相关公众号中LOGO展示\n车队对外宣讲PPT中品牌LOGO展示\n赞助商展板中品牌LOGO展示"
    }, {
      title: "社会宣传",
      content: "车队相关活动新闻稿中介绍"
    }, {
      title: "网络宣传",
      content: "车队网络平台赞助商页面中英文介绍并附上企业官网链接\n车队展板介绍，并附企业公众号二维码"
    }, {
      title: "推广回报",
      content: "赛季不超过两次的赛车优先使用权 （可用于商业展览会及公司内部展示等）\n使用车队平台发布招聘或活动信息\n企业可使用车队队名、LOGO、赛车外观、赛车名称等用于企业产品宣传\n赛季结束，企业将收到详细赞助反馈总结 \n企业可与其他友队及赞助商建立良好关系 , 扩大影响力，提升业内品牌知名度"
    }, {
      title: "人才培养",
      content: "企业可与高校实验室优先合作开发研究项目\n车队可推荐优秀队员赴企业实习\n车队组织队员及相关专业学生赴企业参观交流"
    }]
  }


  const zuanshi_sponsor = {
    title: '成为钻石赞助商',
    condition: '等效赞助10万元人民币以上',
    rewards: [{
      title: "企业品牌宣传",
      content: "车身侧面展示（20cm*30cm）\n正面展示（10cm*20cm）\n前翼展示（10cm*20cm）\n车队对外宣传手册中，产品、品牌 LOGO 展示 \n车队相关公众号中LOGO展示\n车队宣传视频等资料中产品、品牌LOGO展示\n车队对外宣讲PPT中品牌LOGO展示\n赞助商展板中品牌LOGO展示"
    }, {
      title: "社会宣传",
      content: "车队相关活动新闻稿中介绍\n车队举办相关活动中设置专属展台"
    }, {
      title: "网络宣传",
      content: "车队网络平台赞助商页面中英文介绍并附上企业官网链接\n对外宣传手册、PPT 等资料介绍，并附企业公众号二维码 \n车队展板介绍，并附企业公众号二维码"
    }, {
      title: "推广回报",
      content: "赛季不超过三次的赛车优先使用权 （可用于商业展览会及公司内部展示等） \n受邀出席车队举办的各类宣传活动并致辞，如新车发布会、媒体专访等 \n使用车队平台发布招聘或活动信息\n企业可使用车队队名、LOGO、赛车外观、赛车名称等用于企业产品宣传\n企业将定期收到工作进度汇报邮件， 开展技术与运营方面的交流学习 \n赛季结束，企业将收到详细赞助反馈总结 \n企业可与其他友队及赞助商建立良好关系 , 扩大影响力，提升业内品牌知名度"
    }, {
      title: "人才培养",
      content: "企业可与高校实验室优先合作开发研究项目\n车队将推荐优秀队员赴企业实习\n车队组织队员及相关专业学生赴企业参观交流\n企业可优先与车队合作举办高校招聘会 \n企业拥有邀请优秀队员实习的优先权"
    }]
  }




  const [toBeContent, setToBeContent] = useState(zuanshi_sponsor);



  const ButtonRow = () => (
    < Row justify='center' align='middle' className="ButtonRow"  >
      <Col xs={9} sm={9} md={4} >
        <Button className="ButtonRow-Button" style={{ backgroundColor: "#13c2c2" }} onClick={() => {
          setToBeContent(zuanshi_sponsor)
        }}>钻石赞助商</Button>
      </Col>
      <Col xs={9} sm={9} md={4} >
        <Button className="ButtonRow-Button" style={{ backgroundColor: "#faad14" }} onClick={() => {
          setToBeContent(jinpai_sponsor)
        }}>金牌赞助商</Button>
      </Col>
      <Col xs={9} sm={9} md={4} >
        <Button className="ButtonRow-Button" style={{ backgroundColor: "#8c8c8c" }} onClick={() => {
          setToBeContent(yinpai_sponsor)
        }}>银牌赞助商</Button>
      </Col>
      <Col xs={9} sm={9} md={4} >
        <Button className="ButtonRow-Button" style={{ backgroundColor: "#1890ff" }} onClick={() => {
          setToBeContent(partner)
        }} >合作伙伴</Button>
      </Col>
    </Row >
  )


  //内容
  const ToBeContent = () => (
    <Row justify='center' align='middle' className="tobeContent" >
      <Col span={16} >
        <h1 className="tobeContent-h1">{toBeContent.title}</h1>
        <p className='tobeContent-title'>条件</p>
        <p className='tobeContent-content'>{toBeContent.condition}</p>
        <h1 className="tobeContent-get">你将获得</h1>
        {
          toBeContent.rewards.map(item => {
            return (
              <>
                <p className="tobeContent-title">{item.title}</p>
                <p className='tobeContent-content'>{item.content}</p>
              </>
            )
          })
        }
      </Col>
      <Col span={16} className='tobeContent-contact'>
        <h1>联系我们</h1>
        <div>
          <p >电话：15118078010</p>
          <p >邮箱：songdonghong@szturacing.com</p>
        </div>
      </Col>
    </Row>
  )



  return (
    <div>
      <TopImageShow src={images.index} text={'成为我们的赞助商'} />
      <ButtonRow />
      <ToBeContent />
    </div>
  )
}

export default ToBeSponsor