import React from 'react'
import { Row, Col } from 'antd'
import './topImageShow.less'

// 图片部分
const TopImageShow = (props) => (
  <Row>
    <Col span={24}>
      <div className="TopImageShow">
        <img src={props.src} />
        <div>
          <h1>{props.text}</h1>
          <p>{props.content}</p>
        </div>
      </div>
    </Col>
  </Row>


  // <Row>
  //   <Col span={24}  >
  //     <div className='topImg' style={{ backgroundImage: 'url(' + props.src + ')' }}>
  //       <h1>{props.text}</h1>
  //       <p>{props.content}</p>
  //     </div>
  //   </Col>
  // </Row>


)
export default TopImageShow