import React, { useState } from 'react';
import { Carousel, Col, Row, Image, Button, Space } from 'antd';
import Slogan from '../../components/slogan/Slogan';
import TopImageShow from '@/components/topImageShow/TopImageShow';
import { Link } from "react-router-dom";
import './index.less'


function Login (props) {

  const [images, setImages] = useState({
    'index': 'https://images-1304504321.cos.ap-guangzhou.myqcloud.com/images/%E9%A6%96%E9%A1%B5/index1.jpg',
    'baha': 'https://images-1304504321.cos.ap-guangzhou.myqcloud.com/images/%E9%A6%96%E9%A1%B5/U8.jpg',
    'fangchengshi': 'https://images-1304504321.cos.ap-guangzhou.myqcloud.com/images/%E9%A6%96%E9%A1%B5/E23.png',
    'vedio': 'https://images-1304504321.cos.ap-guangzhou.myqcloud.com/images/%E9%A6%96%E9%A1%B5/vedio.mp4'
  })


  //视频展示
  const VideoShow = () => (
    <div className="vedioShow">
      <Row justify="center" align='middle' gutter={[30, 0]}>
        <Col span={24} className="vedioShow-title">视频合集</Col>
        <Col xs={23} sm={23} md={8} >
          {/* <video src={images.vedio} height="100%" width='100%' controls="controls" /> */}
          <iframe width='100%' height='80%' src="//player.bilibili.com/player.html?aid=248000213&bvid=BV1Gv41157Pz&cid=337622178&page=1" scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true"> </iframe>
          <h3>两周年视频</h3>
        </Col>
        <Col xs={23} sm={23} md={8} >
          {/* <video src={images.vedio} height="100%" width='100%' controls="controls" /> */}
          <iframe src="//player.bilibili.com/player.html?aid=633967177&bvid=BV1Vb4y187Rn&cid=435729939&page=1" scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true"> </iframe>
          <h3>E23渲染视频</h3>
        </Col>
        <Col xs={23} sm={23} md={8} >
          {/* <video src={images.vedio} height="100%" width='100%' controls="controls" /> */}
          <iframe height='80%' width='100%' src="//player.bilibili.com/player.html?aid=248000213&bvid=BV1Gv41157Pz&cid=337622178&page=1" scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true"> </iframe>
          <h3>方程式赛车</h3>
        </Col>
      </Row>
    </div >
  )

  // 我们的赛车
  const OurCar = () => (
    <div className="carPart">

      <Row type="flex" justify="center" align='middle' gutter={[100, 30]} >
        <Col span={24} className='carPart-title'>我们的赛车</Col>
        <Col xs={18} sm={18} md={18} lg={12} xl={12} align='middle'>
          <Link to='/car/U8' >
            <div className="U8">
              <img src={images.baha} />
              <div>
                <h1>巴哈赛车</h1>
              </div>
            </div>
          </Link>
        </Col>

        <Col xs={18} sm={18} md={18} lg={12} xl={12} align='middle'>
          <Link to='/car/E23'>
            <div className="U8">
              <img src={images.fangchengshi} />
              <div>
                <h1>方程式赛车</h1>
              </div>
            </div>
          </Link>
        </Col>
      </Row>
    </div >
  )


  // 加入我们
  const JoinUs = () => (
    <Row className="joinPart" type="flex" justify="center" align='middle'>
      <Col span={24} >
        <p>加入我们，成为工作室的一员</p>
        <Link to='/joinUs'>
          <Button type="primary" size="large" >加入我们</Button>
        </Link>
      </Col>
    </Row>
  )


  // 成为赞助商
  const Sponsor = () => (
    <Row className="sponsorPart" justify='center' align='middle'>
      <Col xs={15} sm={15} md={8} className="sponsorPart-button">
        <Link to='/sponsor'>
          <Button type="primary" size="large" >成为赞助商</Button>
        </Link>
      </Col>
      <Col xs={24} sm={24} md={14}><span>为实现我们的梦想做出贡献</span></Col>
    </Row>
  )

  //最新内容
  const [contentArray, setContentArray] = useState([
    {
      img: "https://images-1304504321.cos.ap-guangzhou.myqcloud.com/images/%E9%A6%96%E9%A1%B5/index1.jpg"
    },
    {
      img: "https://images-1304504321.cos.ap-guangzhou.myqcloud.com/images/%E9%A6%96%E9%A1%B5/U8.jpg"
    },
    {
      img: "https://images-1304504321.cos.ap-guangzhou.myqcloud.com/images/%E9%A6%96%E9%A1%B5/E23.png"
    },
  ])
  const NewContent = () => (
    <Row className="contentPart" type="flex" justify="center"  >
      <Col span={22} className='contentPart-title'>最新内容</Col>
      {
        contentArray.map(item => (
          <Col span={7} align='middle'>
            <Link to={'/media'}>
              <img src={item.img} style={{ width: "70%", height: "auto" }} ></img>
            </Link>
          </Col>
        )
        )
      }
    </Row >
  )


  return (
    < >
      <TopImageShow src={images.index} />
      <VideoShow />
      <Slogan text={'永远年轻，永远热泪盈眶'} />
      <OurCar />
      {/*<JoinUs />*/}
      {/*<Sponsor />*/}
      <NewContent />
    </>

  )
}

export default Login