import React, { useState, useEffect } from 'react'
import { Row, Col, Menu, Dropdown, BackTop, Affix, Drawer, Space, Button } from 'antd'
import './header.less'
const { SubMenu } = Menu;


const Header = (e) => {

  const [showHeader, setShowHeader] = useState(true)
  useEffect(() => {
    const urlParams = new URL(window.location.href);
    const pathname = urlParams?.pathname;
    console.log("pathname:", pathname)
    if (pathname.substr(0, 6) === "/admin") {
      setShowHeader(false)
    }
  }, [])


  const [drawerVisible, setDrawerVisible] = useState(false)

  if (showHeader) {
    return (
      <Affix >
        <Row className='header' justify="center" align='middle'>

          {/* 手机导航栏 */}
          <Col xs={24} sm={24} md={0}>
            <Row align='middle'>
              <Col xs={20} sm={20} md={0} offset={1}>
                <a href='/'><img src={require('./logo.png').default} style={{ height: '40px', }} ></img></a>
              </Col>
              <Col xs={2} sm={2} md={0}>
                <div onClick={() => {
                  setDrawerVisible(!drawerVisible)
                }} className="header-drawerSelf" >
                  <span className={'header-drawerSelf-top' + (drawerVisible ? '-checked' : '')}>
                    <span></span>
                  </span>
                  <span className={'header-drawerSelf-bottom' + (drawerVisible ? '-checked' : '')}>
                    <span></span>
                  </span>
                </div>
              </Col>
            </Row>
          </Col>


          <Col xs={0} sm={0} md={24} >
            <Menu mode="horizontal" theme="dark" className="header-menu">
              <Menu.Item className="header-menu-item">
                <a href='/about'>关于我们</a>
              </Menu.Item>
              <Menu.Item className="header-menu-item">
                <a href='/team'>团队</a>
              </Menu.Item>
              <SubMenu title="赛车" className="header-menu-item header-menu-subMenu">
                <Menu.ItemGroup title="巴哈赛车" >
                  <Menu.Item ><a href='/car/U8' >U8</a></Menu.Item>
                </Menu.ItemGroup>
                <Menu.ItemGroup title="方程式赛车">
                  <Menu.Item ><a href='/car/E23' >E23</a></Menu.Item>
                </Menu.ItemGroup>
              </SubMenu>
              <Menu.Item >
                <div className="header-menu-logo"><a href='/'><img src={require('./logo.png').default} ></img></a></div>
              </Menu.Item>
              <Menu.Item className="header-menu-item">
                <a href='/sponsor' >赞助商</a>
              </Menu.Item>
              <Menu.Item className="header-menu-item">
                <a href='/media' >媒体</a>
              </Menu.Item>
              <Menu.Item className="header-menu-item" >
                <a href='/joinUs' >加入我们</a>
              </Menu.Item>
            </Menu>
          </Col>
        </Row>

        <div className="header-drawerDwon" style={{ height: drawerVisible ? '100%' : 'auto' }} >
          <Drawer
            mask={true}
            maskClosable={true}
            placement='top'
            closable={false}
            visible={drawerVisible}
            onClose={() => {
              setDrawerVisible(!drawerVisible)
            }}
            // size='large'
            height="350px"
            getContainer={false}
            style={{ position: 'absolute' }}
          >
            <Menu mode="inline" style={{ width: '100%' }}>
              <Menu.Item >
                <a href='/about'>关于我们</a>
              </Menu.Item>
              <Menu.Item >
                <a href='/team'>团队</a>
              </Menu.Item>
              <SubMenu title="赛车">
                <Menu.ItemGroup title="巴哈赛车" >
                  <Menu.Item ><a href='/car/U8' >U8</a></Menu.Item>
                </Menu.ItemGroup>
                <Menu.ItemGroup title="方程式赛车">
                  <Menu.Item ><a href='/car/E23' >E23</a></Menu.Item>
                </Menu.ItemGroup>
              </SubMenu>
              <Menu.Item >
                <a href='/sponsor' >赞助商</a>
              </Menu.Item>
              <Menu.Item >
                <a href='/media' >媒体</a>
              </Menu.Item>
              <Menu.Item  >
                <a href='/joinUs' >加入我们</a>
              </Menu.Item>
            </Menu>
          </Drawer>
        </div>


        <BackTop />
      </Affix >
    )
  } else {
    return (
      <span></span>
    )
  }

}

export default Header