import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ScrollToTop from '../components/ScrollToTop';
import Error from './error/error';
import index from './index/index'
import About from './about/about'
import Car from './car/car';
import Team from './team/team';
import Sponsor from './sponsor/sponsor';
import ToBeSponsor from './sponsor/toBeSponsor';
import Media from './media/media';
import Join from './joinUs/joinUs';
import JoinDepartment from './joinUs/joinDepartment';
import ApplyJoinUs from './joinUs/ApplyJoinUs';
import WeChat from './media/wechat';
import Photos from './media/photos';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';

// 路由界面
function BasicRoute () {
  return (
    <Router>
      <ScrollToTop >
        <Header></Header>
        <Switch>
          <Route path="/" exact component={index} />
          <Route path="/about" component={About}></Route>
          <Route path="/team" component={Team}></Route>
          <Route path="/car/:carName" component={Car}></Route>
          <Route path="/sponsor" exact component={Sponsor}></Route>
          <Route path="/sponsor/tobe" component={ToBeSponsor} />
          <Route path="/media" exact component={Media}></Route>
          <Route path="/media/wechat" component={WeChat}></Route>
          <Route path="/media/photos" component={Photos}></Route>
          <Route path="/joinUs" exact component={Join}></Route>
          <Route path="/joinUs/:department" component={JoinDepartment}></Route>Error
          <Route path="/joinUs/department/applyJoinUs" component={ApplyJoinUs}></Route>
          <Route component={Error}></Route>
        </Switch>
        <Footer></Footer>
      </ScrollToTop >
    </Router>
  )
}
export default BasicRoute