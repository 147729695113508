import React, { useState } from 'react';
import TopImageShow from '../../components/topImageShow/TopImageShow';
import { Carousel, Col, Row, Image, Button, Space } from 'antd';
import './team.less'
export default function Team () {

  const [images, setImages] = useState({
    'index': 'https://images-1304504321.cos.ap-guangzhou.myqcloud.com/images/%E5%9B%A2%E9%98%9F/index.png'
  })

  const team1 = {
    name: '开发部',
    photos: [
      {
        name: "name",
        introduction: "可达鸭\n可达鸭可达鸭\n可达鸭n可达鸭n可达鸭\n",
        src: "https://img2.baidu.com/it/u=4006182660,1787276285&fm=26&fmt=auto",
      },
      {
        name: "name",
        introduction: "可达鸭\n可达鸭可达鸭\n可达鸭n可达鸭n可达鸭\n",
        src: "https://img2.baidu.com/it/u=4006182660,1787276285&fm=26&fmt=auto",
      },
      {
        name: "name",
        introduction: "可达鸭\n可达鸭可达鸭\n可达鸭n可达鸭n可达鸭\n",
        src: "https://img2.baidu.com/it/u=4006182660,1787276285&fm=26&fmt=auto",
      },
      {
        name: "name",
        introduction: "可达鸭\n可达鸭可达鸭\n可达鸭n可达鸭n可达鸭\n",
        src: "https://img2.baidu.com/it/u=4006182660,1787276285&fm=26&fmt=auto",
      },
      {
        name: "name",
        introduction: "可达鸭\n可达鸭可达鸭\n可达鸭n可达鸭n可达鸭\n",
        src: "https://img2.baidu.com/it/u=4006182660,1787276285&fm=26&fmt=auto",
      },
    ]
  }

  const team2 = {
    name: '产品部',
    photos: [
      {
        name: "name",
        introduction: "可达鸭\n可达鸭可达鸭\n可达鸭n可达鸭n可达鸭\n",
        src: "https://img2.baidu.com/it/u=4006182660,1787276285&fm=26&fmt=auto",
      },
      {
        name: "name",
        src: "https://img2.baidu.com/it/u=4006182660,1787276285&fm=26&fmt=auto",
      },
      {
        name: "name",
        src: "https://img2.baidu.com/it/u=4006182660,1787276285&fm=26&fmt=auto",
      },
      {
        name: "name",
        src: "https://img2.baidu.com/it/u=4006182660,1787276285&fm=26&fmt=auto",
      },
      {
        name: "name",
        src: "https://img2.baidu.com/it/u=4006182660,1787276285&fm=26&fmt=auto",
      },
    ]
  }


  const TeamPhotoShow = (e) => {
    return (
      <Row justify="center" className="TeamPhotoShow" style={{ backgroundColor: e.backgroundColor }}>
        <Col span={20} className='TeamPhotoShow-teamName'>{e.team.name}</Col>
        <Col span={20}>
          <Row justify='start' align='middle' gutter={[50, 50]}>
            {
              e.team.photos.map(item => (
                <Col xs={12} sm={12} md={6} >
                  <div className="TeamPhotoShow-photo">
                    <img src={item.src} width="100%" />
                    <div className="TeamPhotoShow-introduction">
                      {item.introduction}
                    </div>
                  </div>
                  <div className='TeamPhotoShow-name'>{item.name}</div>
                </Col>
              ))
            }
          </Row>
        </Col>
      </Row>
    )
  }


  return (
    <div>
      <TopImageShow text={'团队'} src={images.index} />
      <TeamPhotoShow team={team1} />
      <TeamPhotoShow team={team2} backgroundColor="#fafafa" />
    </div>
  );
}
