import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {Button, Row, Col} from 'antd'
import TopImageShow from '../../components/topImageShow/TopImageShow';
import './sponsor.less'
import axios from "axios";

export default function Sponsor() {
    // 声明一个新的叫做 “count” 的 state 变量
    const [images, setImages] = useState({
        'index': 'https://images-1304504321.cos.ap-guangzhou.myqcloud.com/images/%E8%B5%9E%E5%8A%A9%E5%95%86/index.jpg'
    })

    const [sponsorList_1, setSponsorList_1] = useState(handleClick(1))
    const [sponsorList_2, setSponsorList_2] = useState(handleClick(2))
    const [sponsorList_3, setSponsorList_3] = useState(handleClick(3))
    const [sponsorList_4, setSponsorList_4] = useState(handleClick(4))
    const base_url = "https://szturacing.com/api/"

    function handleClick(type) {

        let get_sponsor_api = "https://szturacing.com/api/sponsors/all?type=" + type
        axios.get(get_sponsor_api)
            .then(function (response) {
                let data = response.data
                switch (type) {
                    case 1:
                        setSponsorList_1(data);
                        return;
                    case 2:
                        setSponsorList_2(data);
                        return;
                    case 3:
                        setSponsorList_3(data);
                        return;
                    case 4:
                        setSponsorList_4(data);
                        return;
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    const ShowContent = () => (
        <div className='showContent'>
            <Row justify='center' style={{textAlign: 'center'}}>
                <Col span={22}><h1 className='showContent-title'>感谢我们出色的赞助商</h1></Col>
                <Col xs={20} sm={16} md={14}
                     className='showContent-content'>非常感谢我们的赞助商，你们的重要支持带给我们无限的力量。因为你们的物资与财力支持，我们的工作进行的更加顺利。</Col>
                <Col span={22}><h1 className='showContent-title'>您想成为我们的赞助商吗？</h1></Col>
                <Col xs={20} sm={16} md={14}
                     className='showContent-content'>如果你的心也为技术和赛车而跳动，那么成为我们梦想的一部分！作为我们项目的合作伙伴，您可以在捐赠或赞助合同框架内为我们提供您的专有知识、实物捐赠以及现金福利。作为回报，我们会为您进行相应的宣传。</Col>
                <Col xs={20} sm={16} md={14}>
                    <Link to='/sponsor/tobe'>
                        <Button size="large" shape='round' className='showContent-button'>现在成为赞助商!</Button>
                    </Link>
                </Col>
            </Row>
        </div>
    )

    const SponsorShow = (list) => {
        return (
            <Row justify="center">
                <Col span={20}>
                    <Row justify='start' align='middle' gutter={[50, 50]}>
                        {
                            list.map(item => (
                                <Col xs={12} sm={12} md={6}>
                                    <div className="TeamPhotoShow-photo">
                                        <img src={base_url + item.logo} width="100%"/>
                                    </div>
                                    <div className='TeamPhotoShow-name'>{item.name}</div>
                                </Col>
                            ))
                        }
                    </Row>
                </Col>
            </Row>
        )
    }

    const SponsorLogo = () => (
        <div className="sponsorLogo">
            <Row justify='center' style={{textAlign: 'center'}}>
                <Col span={18} className="sponsorLogo-title">钻石</Col>
                {
                    sponsorList_1 == null ? (<Col span={18} className="sponsorLogo-empty">暂无</Col>) :
                        <SponsorShow list={sponsorList_1}/>
                }
                <Col span={18} className="sponsorLogo-title">金牌</Col>
                <Col span={18} className="sponsorLogo-empty">暂无</Col>
                <Col span={18} className="sponsorLogo-title">银牌</Col>
                <Col span={18} className="sponsorLogo-empty">暂无</Col>
                <Col span={18} className="sponsorLogo-title">合作伙伴</Col>
                <Col span={18} className="sponsorLogo-empty">暂无</Col>
            </Row>
        </div>
    )

    return (
        <div>
            <TopImageShow src={images.index} text={'我们的赞助商'}/>
            <ShowContent/>
            <SponsorLogo/>
        </div>
    );
}
