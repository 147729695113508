import React, { useState, useEffect } from 'react'
import { Modal, Form, Button, Col, Row, Input, Select, notification, message } from 'antd';
import './joinUs.less'
import api from '../../api/common/index'
const { Option } = Select;

export default function JoinDepartment (props) {


  const [technology_images, setTechnology_images] = useState({
    'index': 'https://images-1304504321.cos.ap-guangzhou.myqcloud.com/images/%E5%8A%A0%E5%85%A5%E6%88%91%E4%BB%AC/%E6%8A%80%E6%9C%AF/index.jpg',
  })

  useEffect(() => {
    let department = props.match.params.department

  }, []);


  const departments = [
    {
      name: "动力与控制部",
      team: [
        {
          name: '电机组',
          tasks: [
            '电机选型',
            '电机控制器选型',
            '电机控制器的调试',
            '电机支架的设计',
          ],
          researches: [
            '电机学',
            '无刷直流电机与永磁同步电机',
            'PWM调制',
            '矢量控制',
            'Catia、Solidworks与Autocad绘图'
          ]
        },
        {
          name: '电池组',
          tasks: [
            '电池单体选型',
            '电池箱体设计',
            '电池模组设计',
            '电池箱体与模组装配',
            '电池采集板的设计',
            'BMS调试',
            'BMS制作与算法设计（高阶）'
          ],
          researches: [
            '电池的基本原理与特性',
            '电工学相关理论',
            '电路设计与PCB绘制',
            'BMS算法',
            'Catia或Solidworks绘图',
          ]
        },
        {
          name: '整车控制组',
          tasks: [
            '整车动力学仿真模型搭建',
            'Simulink控制策略算法模型搭建',
            '传感器选型',
            '传感器标定',
            '整车通讯协议制定'
          ],
          researches: [
            '汽车理论',
            '车辆横纵向动力学',
            '控制理论（经典控制与现代控制）',
            'Matlab与Simulink',
            '整车动力学仿真软件（Carsim、VI-Grade或AVL-Cruise等）',
            'Can总线通讯协议'
          ]
        },
        {
          name: '传动组',
          tasks: [
            '传动比的设计计算',
            '齿轮箱设计',
            '链轮及其支架设计',
            '零件静力学仿真',
            '零件拓扑优化',
          ],
          researches: [
            '汽车理论——汽车的动力性',
            'Matlab与Simulink',
            '机械原理与设计',
            'CAE软件（Ansys、Hypermesh等）',
            'Catia、Solidworks与Autocad绘图'
          ]
        },
        {
          name: '冷却与线束组',
          tasks: [
            '热力学仿真',
            '流体仿真',
            '水冷排选型',
            '风扇选型',
            '水箱选型与设计',
            '冷却管路布置',
            '线束走线设计'
          ],
          researches: [
            '流体力学',
            '热力学',
            '流体与热力学仿真软件',
            'Catia或Solidworks绘图'
          ]
        },
        {
          name: '发动机组',
          tasks: [
            '进气系统与增压器的选型改装与匹配',
            '排气及消音系统的选型改装与匹配',
            '燃油系统的选型改装与匹配',
            '发动机的标定与调教',
            '气动换挡的设计',
          ],
          researches: [
            '发动机原理',
            '热力学',
            '流体力学',
            '流体与热力学仿真软件',
            '发动机标定台架的使用',
            '单片机开发',
            'Catia、Solidworks与Autocad绘图',
          ]
        },
        {
          name: '安全回路与数采',
          tasks: [
            '绘制符合规则要求的低压安全',
            '回路PCB板',
            '车载显示屏程序设计',
            '数据采集系统程序设计'
          ],
          researches: [
            '电工学相关理论',
            '电路设计与PCB绘制',
            'STM32嵌入式开发',
            '树莓派开发'
          ]
        },
      ]
    },
    {
      name: "车身部",
      team: [
        {
          name: '车架组',
          tasks: [
            '车架的设计',
            '车架后期制造工作'
          ],
          researches: [
            'Catia建模',
            'Ansys仿真建模与分析'
          ]
        },
        {
          name: '车身及复合材料组',
          tasks: [
            '设计车身',
            '使用复合材料制作车身'
          ],
          researches: [
            'Catia建模',
            '碳纤维复合技术',
            'Ansys仿真',
          ]
        },
        {
          name: '空套组',
          tasks: [
            '.整车的空气动力学套件设计，验证与制造',
          ],
          researches: [
            'Catia建模',
            'ANSYS Flunet 空气动力学仿真',
          ]
        },
        {
          name: '整车布置组',
          tasks: [
            '整车的建模总装',
            '整车实体部件装配辅助工作'
          ],
          researches: [
            'Catia装配',
          ]
        },
        {
          name: '人机工程组',
          tasks: [
            '座舱的人机工程设计',
          ],
          researches: [
            'Catia建模',
            '人机工程学',
          ]
        },
      ]
    },
    {
      name: "底盘部",
      team: [
        {
          name: '悬架组',
          tasks: [
            '悬架几何参数的设计，实车调试反馈调教，控制车身姿态，通过合理设计来最大程度发挥轮胎性能',
          ],
          researches: [
            '理论力学',
            '汽车理论',
            'Ansys分析',
            'Catia或Solidworks绘图',
            'Adams车辆动力模型建模',
          ]
        },
        {
          name: '转向组',
          tasks: [
            '设计合适转向传动比，设计轻量高效的转向系统，让赛车动态响应更加灵敏精准，使得赛车操纵更加稳定',
            '使用复合材料制作车身'
          ],
          researches: [
            '理论力学',
            '汽车理论',
            'Ansys分析',
            'Matlab优化转向梯形',
            'Catia或Solidworks绘图',
            'ADAMS CAR仿真优化',
          ]
        },
        {
          name: '轮边组',
          tasks: [
            '连接悬架与轮胎-将悬架几何体现到轮胎上',
            '轻量化-减轻簧下质量，提升整车性能',
            'Ansys有限元分析轮边系统零件',
            '对轮边系统零件进行拓扑优化',
          ],
          researches: [
            '理论力学',
            '汽车理论',
            'Ansys分析',
            'Catia或Solidworks绘图',
          ]
        },
        {
          name: '制动组',
          tasks: [
            '设计一套前后制动力分配比可调，同时满足赛车在制动过程能实现四轮同时抱死且不跑偏的制动系统',
            '设计制动踏板，油门踏板',
            'Matlab制动系统仿真',
            'Ansys有限元分析制动系统零件',
            '优化制动系统',
          ],
          researches: [
            '理论力学',
            '汽车理论',
            'Ansys分析',
            'Catia或Solidworks绘图',
            'Matlab制动优化',
          ]
        },
      ]
    },
    {
      name: "测试及车手组",
      team: [
        {
          name: '测试及车手',
          tasks: [
            '了解全车各部分的结构，对车子各部分有充分了解',
            '观看赛车比赛视频，学习如何控车',
            '在LFS上，绘制不同赛区、不同年份的赛道绕桩地图',
            '参与网上举办的模拟器比赛',
            '锻炼身体，尤其是脖子、手臂、腰部以及腿部力量'
          ],
          researches: [
            '汽车理论',
            '研究比赛视频'
          ]
        },
      ]
    }
  ]

  const [departmentShowIndex, setDepartmentShowIndex] = useState(0)

  // 头部图片显示
  const TopImage = () => (
    <div className="TopImage" style={{ backgroundImage: "url(" + technology_images.index + ")" }}>
      <Row justify="center" align='middle' gutter={[0, 30]} >
        {
          // departments.map((item, index) => {
          //   return (
          //     <Col xm={24} sm={24} md={9}>
          //       <Button className="TopImage-button" size='large' style={{ backgroundColor: departmentShowIndex === index ? '#531dab' : '#595959' }} onClick={() => {
          //         setDepartmentShowIndex(0)
          //       }} >{item.name}</Button>
          //     </Col>
          //   )
          // })
        }
        <Col xm={24} sm={24} md={9}>
          <Button className="TopImage-button" size='large' style={{ backgroundColor: departmentShowIndex === 0 ? '#531dab' : '#595959' }} onClick={() => {
            setDepartmentShowIndex(0)
          }} >动力与控制部</Button>
        </Col>
        <Col xm={24} sm={24} md={9}>
          <Button className="TopImage-button" size='large' style={{ backgroundColor: departmentShowIndex === 1 ? '#531dab' : '#595959' }} onClick={() => {
            setDepartmentShowIndex(1)
          }}>车身部</Button>
        </Col>
        <Col xm={24} sm={24} md={9}>
          <Button className="TopImage-button" size='large' style={{ backgroundColor: departmentShowIndex === 2 ? '#531dab' : '#595959' }} onClick={() => {
            setDepartmentShowIndex(2)
          }}>底盘部</Button>
        </Col>
        <Col xm={24} sm={24} md={9}>
          <Button className="TopImage-button" size='large' style={{ backgroundColor: departmentShowIndex === 3 ? '#531dab' : '#595959' }} onClick={() => {
            setDepartmentShowIndex(3)
          }}>测试及车手</Button>
        </Col>
      </Row>
    </div>
  )


  // 部门各组要求
  const DepartmentRow = () => {
    return (
      <div>
        {
          departments[departmentShowIndex].team.map((team, index) => {
            return (
              <Row justify='center' className="DepartmentRow" style={{ backgroundColor: index % 2 === 0 ? '#fafafa' : 'white' }} >
                <Col span={24} className="DepartmentRow-teamTitle"><h1>{team.name}</h1></Col>
                <Col xs={20} sm={14} md={5} className="DepartmentRow-content" >
                  <h2>任务</h2>
                  {
                    team.tasks.map((task, index) => {
                      return (
                        <p>{index + 1}. {task}</p>
                      )
                    })
                  }
                </Col>
                <Col xs={0} sm={0} md={3}></Col>
                <Col xs={20} sm={14} md={5} className="DepartmentRow-content" >
                  <h2>研究</h2>
                  {
                    team.researches.map((research, index) => {
                      return (
                        <p>{index + 1}. {research}</p>
                      )
                    })
                  }
                </Col>
              </Row>
            )
          })
        }
      </div>
    )
  }


  // 加入我们按钮
  const JoinUsButtonRow = () => {
    // const collegs = [
    //   {
    //     name: '中德智能制造学院',
    //     majors: [
    //       '机械设计制造将其自动化',
    //       '电子科学与技术',
    //       '自动化'
    //     ]
    //   },
    //   {
    //     name: '大数据与互联网学院',
    //     majors: [
    //       '物联网工程',
    //       '计算机科学与技术',
    //       '数据科学与大数据技术'
    //     ]
    //   },
    //   {
    //     name: '创意设计学院',
    //     majors: [
    //       '工业设计',
    //       '环境设计',
    //       '艺术与科技'
    //     ]
    //   },
    //   {
    //     name: '质量和标准学院',
    //     majors: [
    //       '自动化（控制系统国际标准化）',
    //       '智能医学工程（医疗仪器国际标准化）'
    //     ]
    //   },
    //   {
    //     name: '城市交通与物流学院',
    //     majors: [
    //       '车辆工程',
    //       '汽车服务工程',
    //       '交通运输',
    //       '物流管理'
    //     ]
    //   },
    //   {
    //     name: '商学院',
    //     majors: [
    //       '国际商务'
    //     ]
    //   },
    //   {
    //     name: '健康与环境工程',
    //     majors: [
    //       '生物医学工程',
    //       '智能医学工程'
    //     ]
    //   },
    //   {
    //     name: '新材料与新能源学院',
    //     majors: [
    //       '光源与照明',
    //       '新能源科学与工程',
    //       '微电子科学与工程',
    //       '材料科学与工程'
    //     ]
    //   },
    //   {
    //     name: '工程物理学院',
    //     majors: [
    //       '应用物理学',
    //       '光电信息科学与工程'
    //     ]
    //   },
    //   {
    //     name: '外国语学院',
    //     majors: [
    //       '英语',
    //       '商务英语',
    //       '德语'
    //     ]
    //   },
    //   {
    //     name: '药学院',
    //     majors: [
    //       '药学'
    //     ]
    //   },
    //   {
    //     name: '聚龙学院',
    //     majors: [
    //       '汽车服务工程',
    //       '工业设计'
    //     ]
    //   },
    // ]
    const tailLayout = {
      wrapperCol: { offset: 8, span: 16 },
    };
    const [colleges, setColleges] = useState([])
    const [departments_, setDepartments] = useState([])
    const [majors, setMajors] = useState([])
    const [teams, setTeams] = useState([])

    const [moadlVisible, setModalVisible] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [collegeChoose, setCollegeChoose] = useState(-1)
    const [departmentChoose, setDepartmentChoose] = useState(-1)

    useEffect(() => {
      init_load_list()
    }, []);

    const formSubmit = (form) => {
      setConfirmLoading(true);
      form.gender *= 1
      api.application.apply(form).then(() => {
        setTimeout(() => {
          setModalVisible(false);
          setConfirmLoading(false);
          openNotificationWithIcon('success')
        }, 2000);
      }).catch(err => {
        console.log(err)
        message.error("申请失败，系统错误")
        setConfirmLoading(false);
      })
    }

    // 投递成功的消息
    const openNotificationWithIcon = type => {
      notification.open({
        message: '投递成功',
        description:
          '请耐心等待我们的通知，很感谢你选择我们！',
        duration: 10,
      });
    };

    const init_load_list = () => {
      api.application.get_col_dep_list().then(e => {
        setColleges(e.data.colleges)
        setDepartments(e.data.departments)
      })
    }

    const chooseCollege = (e) => {
      setCollegeChoose(e)
      api.application.get_major_list({ 'college_id': e }).then(res => {
        console.log(res)
        setMajors(res.data)
        console.log("majors", majors)
      })
    }

    const chooseDepartment = (e) => {
      setDepartmentChoose(e)
      api.application.get_team_list({ 'department_id': e }).then(res => {
        console.log(res)
        setTeams(res.data)
      })
    }

    return (
      <Row className='JoinUsButtonRow' justify='center' align='middle' >
        <Col span={10}>
          <Button className='JoinUsButtonRow-Button' size="large" onClick={() => {
            setModalVisible(!moadlVisible)
          }} >现在加入我们</Button>
        </Col>
        <Modal
          title="申请信息"
          visible={moadlVisible}
          onCancel={() => { setModalVisible(!moadlVisible) }}
          confirmLoading={confirmLoading}
          footer={null}
        >
          <Form layout="vertical" onFinish={formSubmit} initialValues={{ remember: true }} hideRequiredMark>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="name"
                  label="姓名"
                  rules={[{ required: true, message: '请输入姓名' }]}
                >
                  <Input placeholder="请输入姓名" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="gender"
                  label="性别"
                  rules={[{ required: true, message: '请选择性别' }]}
                >
                  <Select placeholder="请选择性别">
                    <Option value="1">男</Option>
                    <Option value="2">女</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="school_id"
                  label="学号"
                  rules={[{ required: true, message: '请输入学号' }]}
                >
                  <Input placeholder="请输入学号"></Input>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="wechat"
                  label="微信号"
                  rules={[{ required: false, message: '请输入微信号' }]}
                >
                  <Input placeholder="请输入微信号"></Input>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="phone"
                  label="手机号"
                  rules={[{ required: true, message: '请输入手机号' }]}
                >
                  <Input placeholder="请输入手机号"></Input>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="email"
                  label="邮箱"
                  rules={[{ required: true, message: '请输入邮箱' }]}
                >
                  <Input placeholder="请输入邮箱"></Input>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="college_id"
                  label="学院"
                  rules={[{ required: true, message: '请选择学院' }]}
                >
                  <Select placeholder="请选择学院" onChange={e => { chooseCollege(e) }} >
                    {
                      colleges.map((item) => {
                        return (
                          <Option value={item.id}>{item.name}</Option>
                        )
                      })
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="major_id"
                  label="专业"
                  rules={[{ required: true, message: '请选择专业' }]}
                >
                  <Select placeholder="请选择专业" disabled={collegeChoose === -1} >
                    {
                      collegeChoose !== -1
                        ?
                        (
                          majors.map((item) => {
                            return (
                              <Option value={item.id}>{item.name}</Option>
                            )
                          })
                        )
                        : null
                    }
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="department_id"
                  label="意向部门"
                  rules={[{ required: true, message: '请选择意向部门' }]}
                >
                  <Select placeholder="请选择意向部门" onChange={e => { chooseDepartment(e) }}>
                    {
                      departments_.map((item) => {
                        return (
                          <Option value={item.id}>{item.name}</Option>
                        )
                      })
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="team_id"
                  label="意向小组"
                  rules={[{ required: true, message: '请选择意向小组' }]}
                >
                  <Select placeholder="请选择意向小组" disabled={departmentChoose === -1}>
                    {
                      departmentChoose !== -1
                        ?
                        (
                          teams.map((item) => {
                            return (
                              <Option value={item.id}>{item.name}</Option>
                            )
                          })
                        )
                        : null
                    }
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="skill"
                  label="特长"
                  rules={[
                    {
                      required: true,
                      message: '写下你的特长吧~'
                    },
                  ]}
                >
                  <Input.TextArea rows={4} placeholder="写下你的特长吧~" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="knowing"
                  label="对巴哈和方程式赛事有多少的了解"
                  rules={[
                    {
                      required: true,
                      message: '写下你对赛事的了解吧~'
                    },
                  ]}
                >
                  <Input.TextArea rows={4} placeholder="写下你对巴哈和方程式赛事有多少的了解吧~" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="why"
                  label="为什么想加入工作室"
                  rules={[
                    {
                      required: true,
                      message: '写下你想加入我们的原因吧~'
                    },
                  ]}
                >
                  <Input.TextArea rows={4} placeholder="写下你想加入我们的原因吧~" />
                </Form.Item>
              </Col>
            </Row>
            <Row justify='center'>
              <Col span={6}>
                <Form.Item style={{ textAlign: "center" }}>
                  <Button type="primary" htmlType="submit" loading={confirmLoading}>
                    提交
                  </Button>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item style={{ textAlign: "center" }}>
                  <Button htmlType="button" onClick={() => { setModalVisible(!moadlVisible) }}>
                    取消
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </Row >
    )
  }

  return (
    <div>
      <TopImage />
      <DepartmentRow />
      <JoinUsButtonRow />
    </div>
  );
}
