import React, { useState } from 'react';
import { Col, Row } from 'antd';

import TopImageShow from '@/components/topImageShow/TopImageShow';
import Slogan from '../../components/slogan/Slogan';
import './about.less'

export default function About () {
  // 声明一个新的叫做 “count” 的 state 变量
  const [count, setCount] = useState(0);
  const [introducion_content, setIntroducion_content] = useState(
    '在城市交通与物流学院领导的关心和支持下，深圳技术大学赛车工作室于2019年9月成立是以研制巴哈越野赛车、方程式赛车、无人驾驶方程式赛车，以及开展相关创新创业、公益科普活动为目标的科创组织。在今年4月与德国奥格斯堡应用技术大学 Starkstrom赛车工作室正式签署了合作备忘录。车队始终秉持“无限创意，个性设计，自主运营，匠心筑梦”的理念，不断培养学生汽车工程应用能力，致力打造更加优秀的赛车，培育卓越的赛车团队。'
  )

  const [images, setImages] = useState({
    'index': 'https://images-1304504321.cos.ap-guangzhou.myqcloud.com/images/%E5%85%B3%E4%BA%8E%E6%88%91%E4%BB%AC/index.png',
    'team1': 'https://images-1304504321.cos.ap-guangzhou.myqcloud.com/images/%E5%85%B3%E4%BA%8E%E6%88%91%E4%BB%AC/team1.jpg',
    'team2': 'https://images-1304504321.cos.ap-guangzhou.myqcloud.com/images/%E5%85%B3%E4%BA%8E%E6%88%91%E4%BB%AC/team2.jpg',
    'structure': 'https://images-1304504321.cos.ap-guangzhou.myqcloud.com/images/%E5%85%B3%E4%BA%8E%E6%88%91%E4%BB%AC/structure.png'
  })

  // 组织介绍
  const [teamMate_counts, setTeamMate_counts] = useState(46);
  const [car_counts, setCar_counts] = useState(2);
  const [contest_counts, setContest_counts] = useState(1);


  const Introduction = () => (
    <div className="introduction">
      <Row type="flex" justify="center" align="middle" gutter={[60, 50]}>
        <Col xs={24} sm={24} md={10} justify="center">
          <img src={images.team1} width="100%" height="auto" style={{ margin: "auto", display: "block" }}></img>
        </Col>
        <Col xs={24} sm={24} md={10}  >
          <h1 style={{ textAlign: "center" }}>深圳技术大学赛车工作室</h1>
          <p>{introducion_content}</p>
        </Col>
      </Row>
      <Row type="flex" justify="center" align="middle" gutter={[100, 40]} style={{ marginTop: "50px" }}>
        <Col xs={24} sm={24} md={10} >
          <Row type="flex" justify="center" align="middle" >
            <Col span={8} style={{ textAlign: "center" }}>
              <h1>{teamMate_counts}</h1>
              <p>团队成员</p>
            </Col>
            <Col span={8} style={{ textAlign: "center" }}>
              <h1>{car_counts}</h1>
              <p>赛车</p>
            </Col>
            <Col span={8} style={{ textAlign: "center" }}>
              <h1>{contest_counts}</h1>
              <p>比赛</p>
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={10}  >
          <div style={{ width: "100%" }}><img src={images.team2} width="100%" ></img></div>
        </Col>
      </Row>
    </div>
  )


  //组织架构
  const TeamStructure = () => (
    <div className="TeamStructure">
      <Row type='flex' justify='center' align='middle'>
        <Col span={18}>
          <img src={images.structure} width='100%'></img>
        </Col>
      </Row>
    </div>
  )

  return (
    <div>
      {/* <ImgShow /> */}
      <TopImageShow src={images.index} text={'关于我们'} />
      <Slogan text="当新鲜感消失殆尽，剩下的便是热爱和责任" />
      <Introduction />
      <TeamStructure />
    </div>
  );
}