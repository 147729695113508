import React, { useState, useEffect } from 'react';
import { Col, Row, Button } from 'antd'
import { Link } from 'react-router-dom'
import { CheckOutlined } from '@ant-design/icons';
import TopImageShow from '../../components/topImageShow/TopImageShow';
import Slogan from '../../components/slogan/Slogan';
import './joinUs.less'
export default function Join (props) {

  const [images, setImages] = useState({
    'index': 'https://images-1304504321.cos.ap-guangzhou.myqcloud.com/images/%E5%8A%A0%E5%85%A5%E6%88%91%E4%BB%AC/index.jpg',
    'image1': 'https://images-1304504321.cos.ap-guangzhou.myqcloud.com/images/%E5%8A%A0%E5%85%A5%E6%88%91%E4%BB%AC/image1.jpg',
    'image2': 'https://images-1304504321.cos.ap-guangzhou.myqcloud.com/images/%E5%8A%A0%E5%85%A5%E6%88%91%E4%BB%AC/image2.jpg',
  })

  //图片和内容展示
  const ShowContent = () => (
    <div>
      <Row className="ShowContent-Row" align='middle' justify='center' gutter={[0, 12]}>
        <Col xs={24} sm={24} md={11} className='ShowContent-Row-left'>
          <h1>你期待什么？</h1>
          <p><CheckOutlined /> 在专业领域进一步提升</p>
          <p><CheckOutlined /> 收获更多的实践经验</p>
          <p><CheckOutlined /> 与各院系学生合作</p>
          <p><CheckOutlined /> 负责赛车及其周围的小型或大型项目</p>
          <p><CheckOutlined /> 一辆自己参与制作的成品赛车，与全国各地的大学在方程式赛事中竞争</p>
        </Col>
        <Col xs={24} sm={24} md={11} className='ShowContent-Row-right' >
          <img src={images.image1} style={{ width: '80%' }}></img>
        </Col>
      </Row>
      <Row className="ShowContent-Row" align='middle' justify='center'>
        <Col xs={24} sm={24} md={11} className='ShowContent-Row-left' >
          <img src={images.image2} width="80%"></img>
        </Col>
        <Col xs={24} sm={24} md={11} className='ShowContent-Row-right'>
          <h1>你是...</h1>
          <p> 负责的</p>
          <p>具有创造力的</p>
          <p> 热心的</p>
          <p>热爱赛车的</p>
          <p>寻找团队的</p>
          <p style={{ letterSpacing: '2px' }}> .....</p>
        </Col>
      </Row>
    </div>
  )


  const WatingRow = () => (
    <Row className="WatingRow" >
      <Col span={24}><h1>这些领域在等着你</h1></Col>
      <Col span={24}>
        <Link to="/joinUs/technology">
          <Button className='WatingRow-button-left ' size="large"  >技术</Button>
        </Link>
        <Link to="/joinUs/business">
          <Button className='WatingRow-button-right' size="large"  >运营</Button>
        </Link>
      </Col>
    </Row>
  )

  return (
    <div>
      <TopImageShow src={images.index} text="我们在找你" content="每个不曾起舞的日子，都是对生命的辜负" />
      <Slogan text={'招新进行时，期待与你相见！'} />
      <ShowContent />
      <WatingRow />
    </div>
  );
}


