import React, { useState, useEffect } from 'react'
import { Modal, Form, Button, Col, Row, Input, Select, DatePicker, Space } from 'antd';
import './joinUs.less'
const { Option } = Select;

export default function ApplyJoinUs () {

  const collegs = [
    {
      name: '中德智能制造学院',
      majors: [
        '机械设计制造将其自动化',
        '电子科学与技术',
        '自动化'
      ]
    },
    {
      name: '大数据与互联网学院',
      majors: [
        '物联网工程',
        '计算机科学与技术',
        '数据科学与大数据技术'
      ]
    },
  ]

  const departments = [
    {
      name: "动力与控制部",
      team: [
        {
          name: '电机组',
        },
        {
          name: '电池组',
        },
        {
          name: '整车控制组',
        },
        {
          name: '传动组',
        },
        {
          name: '冷却与线束组',
        },
        {
          name: '发动机组',
        },
        {
          name: '安全回路与数采',
        },
      ]
    },
    {
      name: "车身部",
      team: [
        {
          name: '车架组',
        },
        {
          name: '车身及复合材料组',
        },
        {
          name: '空套组',
        },
        {
          name: '整车布置组',
        },
        {
          name: '人机工程组',
        },
      ]
    },
    {
      name: "底盘部",
      team: [
        {
          name: '悬架组',
        },
        {
          name: '转向组',
        },
        {
          name: '轮边组',
        },
        {
          name: '制动组',
        },
      ]
    },
    {
      name: "测试及车手组",
      team: [
        {
          name: '测试及车手',
        },
      ]
    }
  ]

  const [confirmLoading, setConfirmLoading] = useState(false)
  const formSubmit = (e) => {
    setConfirmLoading(true);
    console.log(e)
    setTimeout(() => {
      setConfirmLoading(false);
    }, 2000);
  }

  const ApplyForm = () => (
    <div className='ApplyForm'>
      <Form layout="vertical" hideRequiredMark>
        <Row justify='center' align='middle' gutter={16} >
          <Col span={10}>
            <Form.Item
              name="name"
              label="姓名"
              rules={[{ required: true, message: '请输入姓名' }]}
            >
              <Input placeholder="请输入姓名" />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              name="gender"
              label="性别"
              rules={[{ required: true, message: '请选择性别' }]}
            >
              <Select placeholder="请选择性别">
                <Option value="1">男</Option>
                <Option value="2">女</Option>

              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row justify='center' align='middle' gutter={16}>
          <Col span={10}>
            <Form.Item
              name="shool_id"
              label="学号"
              rules={[{ required: true, message: '请输入学号' }]}
            >
              <Input placeholder="请输入学号"></Input>
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              name="email"
              label="邮箱"
              rules={[{ required: true, message: '请输入邮箱' }]}
            >
              <Input placeholder="请输入邮箱"></Input>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={10}>
            <Form.Item
              name="collge"
              label="学院"
              rules={[{ required: true, message: '请选择学院' }]}
            >
              <Select placeholder="请选择学院">
                {
                  collegs.map((item, index) => {
                    return (
                      <Option value={index}>{item.name}</Option>
                    )
                  })
                }
              </Select>
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              name="major"
              label="专业"
              rules={[{ required: true, message: '请选择专业' }]}
            >
              <Select placeholder="请选择专业">
                {
                  collegs.map((item, index) => {
                    return (
                      <Option value={index}>{item.name}</Option>
                    )
                  })
                }
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={10}>
            <Form.Item
              name="department"
              label="意向部门"
              rules={[{ required: true, message: '请选择意向部门' }]}
            >
              <Select placeholder="请选择意向部门">
                {
                  departments.map((item, index) => {
                    return (
                      <Option value={index}>{item.name}</Option>
                    )
                  })
                }
              </Select>
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              name="team"
              label="意向小组"
              rules={[{ required: true, message: '请选择意向小组' }]}
            >
              <Select placeholder="请选择意向小组">
                {
                  collegs.map((item, index) => {
                    return (
                      <Option value={index}>{item.name}</Option>
                    )
                  })
                }
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="ability"
              label="特长"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input.TextArea rows={4} placeholder="写下你的特长吧~" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="ability"
              label="对巴哈和方程式赛事有多少的了解"
              rules={[
                {
                  required: true,
                  message: '了解不多不要紧，让我们好了解你~'
                },
              ]}
            >
              <Input.TextArea rows={4} placeholder="写下你对巴哈和方程式赛事有的了解吧~" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="ability"
              label="为什么想加入工作室"
              rules={[
                {
                  required: true,
                  message: '这一定要写噢'
                },
              ]}
            >
              <Input.TextArea rows={4} placeholder="写下你想加入我们的原因吧~" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  )

  const App = () => {
    const [visible, setVisible] = React.useState(false);
    const [confirmLoading, setConfirmLoading] = React.useState(false);
    const [modalText, setModalText] = React.useState('Content of the modal');

    const showModal = () => {
      setVisible(true);
    };

    const handleOk = () => {
      setModalText('The modal will be closed after two seconds');
      setConfirmLoading(true);
      setTimeout(() => {
        setVisible(false);
        setConfirmLoading(false);
      }, 2000);
    };

    const handleCancel = () => {
      console.log('Clicked cancel button');
      setVisible(false);
    };

    return (
      <>
        <Button type="primary" onClick={showModal}>
          Open Modal with async logic
        </Button>
        <Modal
          title="Title"
          visible={visible}
          onOk={handleOk}
          confirmLoading={confirmLoading}
          onCancel={handleCancel}
        >
          <p>{modalText}</p>
        </Modal>
      </>
    );
  };

  return (
    <>
      <App></App>
    </>
  )
}