import Curd from '../curd'
import http from '../../lib/http/index'

const base_url = '/common/application'

const application = {

  ...new Curd(base_url),

  apply (form) {
    return http.post("/common/application", form)
  },

  get_col_dep_list () {
    return http.get("/common/collegeDepartmentList")
  },

  get_college_list () {
    return http.get("/common/colleges")
  },

  get_major_list (params) {
    return http.get("/common/majors", { params: params })
  },

  get_department_list () {
    return http.get("/common/departments")
  },

  get_team_list (params) {
    return http.get("/common/teams", { params: params })
  },

}

export default application