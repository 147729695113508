import React, { useState, useEffect } from 'react';
import { Col, Row } from 'antd'
import { Link } from 'react-router-dom'
import TopImageShow from '../../components/topImageShow/TopImageShow';
import './media.less'
export default function Media () {


  const [images, setImages] = useState({
    'index': 'https://images-1304504321.cos.ap-guangzhou.myqcloud.com/images/%E5%AA%92%E4%BD%93/index.jpg',
    'gonzhonhao': "https://images-1304504321.cos.ap-guangzhou.myqcloud.com/images/%E5%AA%92%E4%BD%93/gonzhonhao.jpg",
    "photo": "https://images-1304504321.cos.ap-guangzhou.myqcloud.com/images/%E5%AA%92%E4%BD%93/photo.jpg"
  })

  const MediaRow = () => (
    <Row align='middle' justify='space-around' className="MediaRow" gutter={[0, 40]}>

      <Col xs={22} sm={22} md={10} >
        <Link to='/media/wechat'>
          <div className="MediaRow-background" style={{ backgroundImage: "url(" + images.gonzhonhao + ")" }}>
            <div>公众号</div>
          </div>
        </Link>
      </Col>

      <Col xs={22} sm={22} md={10} >
        <Link to='/media/photos' >
          <div className="MediaRow-background" style={{ backgroundImage: "url(" + images.photo + ")" }}>
            <div>照片</div>
          </div>
        </Link>
      </Col>

    </Row>
  )

  return (
    <div>
      <TopImageShow src={images.index} text="我们的故事" />
      <MediaRow />
    </div>
  );
}
