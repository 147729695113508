import axios from "axios";

/* 创建 axios 实例 */
let http = axios.create({
    baseURL: 'https://www.szturacing.com/api/',
    timeout: 50000,
    withCredentials: false,
    headers: { 'Content-Type': 'application/json', },
    transformRequest: [function (data, headers) {
        if (headers['Content-Type'] === 'multipart/form-data;charset=UTF-8') {
            return data
        } else {
            headers['Content-Type'] = 'application/json'
        }
        return JSON.stringify(data)
    }],
})


// 请求拦截
// http.interceptors.request.use(
//     (config) => {

//         // 兼容 post 跨域问题
//         if (config.method === 'post') {

//             // 修改 Content-Type
//             config.headers['Content-Type'] =
//                 'application/x-www-form-urlencoded';
//             console.log(config)
//             // 将对象参数转换为序列化的 URL 形式（key=val&key=val）
//             config.data = stringify(config.data);
//         }
//         return config;
//     },
//     (error) => {
//         console.log(error);
//         return Promise.reject(error);
//     }
// );

/* http request 拦截器 */
http.interceptors.request.use(
    config => {
        if (localStorage.token) {
            config.headers.Token = localStorage.token
        }
        return config
    },
    err => {
        return Promise.reject(err)
    }
)

/* response 拦截器 */
// http.interceptors.response.use(
//     response => {
//         return Promise.resolve(response.data)
//     },
//     async error => {
//         switch (error.response.status) {
//             case 401:
//                 // 无权访问时，直接登出
//                 // await store.dispatch('logout')
//                 // location.reload()
//                 console.log("no right")
//                 break
//         }
//         return Promise.reject(error)
//     }
// )


export default http