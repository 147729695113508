import React from 'react'
import { Col, Row, Image } from 'antd'
import { Link } from 'react-router-dom'
import { useState, useEffect } from 'react';
import TopImageShow from '../../components/topImageShow/TopImageShow';
import './media.less'
export default function Photos () {

  const [images, setImages] = useState({
    'index': 'https://images-1304504321.cos.ap-guangzhou.myqcloud.com/images/%E5%AA%92%E4%BD%93/%E5%85%AC%E4%BC%97%E5%8F%B7/index.jpg',
  })

  return (

    <div>
      <TopImageShow src={images.index} text="公众号" />

    </div>
  )
}
