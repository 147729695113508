import React, { useState, useEffect } from 'react';
import TopImageShow from '../../components/topImageShow/TopImageShow';
import './media.less'


export default function WeChat () {

  const [images, setImages] = useState({
    'index': 'https://images-1304504321.cos.ap-guangzhou.myqcloud.com/images/%E5%AA%92%E4%BD%93/%E7%85%A7%E7%89%87/index.jpg',
  })

  return (
    <div>
      <TopImageShow src={images.index} text="公众号" />
    </div>
  )
}
