import React, { useState, useEffect } from 'react'
import { Row, Col, Menu, Dropdown, BackTop } from 'antd'
import './footer.less';
const Footer = () => {

  const [showHeader, setShowHeader] = useState(true)
  useEffect(() => {
    const urlParams = new URL(window.location.href);
    const pathname = urlParams?.pathname;
    console.log("pathname:", pathname)
    if (pathname.substr(0, 6) === "/admin") {
      setShowHeader(false)
    }
  }, [])


  if (showHeader) {
    return (
      <div >
        <Row className='Footer' type="flex" align='middle' justify='center' >
          <Col xs={6} sm={4} md={4} offset={1} ><img src={require('./logo.png').default} style={{ width: "80%" }}></img></Col>
          <Col xs={12} sm={10} md={14} className="Footer-center">
            {/*<div>深圳技术大学</div>*/}
            {/*<div>赛车工作室</div>*/}
            <div>赛车分享</div>
          </Col>
          <Col xs={20} sm={8} md={5} pull={1} className="Footer-right" >
            <div>联系我们</div>
            <div>电话：15118078010</div>
            <div>邮箱：songdonghong@szturacing.com</div>
          </Col>
        </Row>
        <Row className="Footer-beian"  >
          <Col span={24}>
            <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2022057038号-1</a>
          </Col>
        </Row>
      </div >
    )
  } else {
    return (
      <span></span>
    )
  }



}

export default Footer