import React from 'react'
import { Row, Col } from 'antd'
import './Slogan.less'

// 标语部分
const Slogan = (props) => (
  <Row type="flex" justify="center" align='middle'>
    <Col span={24} >
      <div className="SolganPart"><span>{props.text}</span></div>
    </Col>
  </Row>
)

export default Slogan