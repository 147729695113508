import React from 'react';
// import { Button } from 'antd'
import './error.less'
export default function Error () {
  return (
    <div class='error'>
      <div class="wrapper">
        <img src={require('./404.svg').default} alt="not found" />
        <p>找不到页面</p>
        {/* <Button type="primary" click="backToRoot">返回首页</Button> */}
      </div>
    </div>
  )
}
