import React, { useState, useEffect } from 'react';
import TopImageShow from '@/components/topImageShow/TopImageShow';
import { Row, Col } from 'antd'
import './car.less'

export default function Car (props) {

  const [U8_images, setU8_images] = useState({
    'index': 'https://images-1304504321.cos.ap-guangzhou.myqcloud.com/images/%E8%B5%9B%E8%BD%A6/%E5%B7%B4%E5%93%88/U8/index.jpg',
    'car1': 'https://images-1304504321.cos.ap-guangzhou.myqcloud.com/images/%E8%B5%9B%E8%BD%A6/%E5%B7%B4%E5%93%88/U8/car1.jpg',
  })

  const [E23_images, setE23_images] = useState({
    'index': 'https://images-1304504321.cos.ap-guangzhou.myqcloud.com/images/%E8%B5%9B%E8%BD%A6/%E6%96%B9%E7%A8%8B%E5%BC%8F/E23/E23.png',
    'car1': 'https://images-1304504321.cos.ap-guangzhou.myqcloud.com/images/%E8%B5%9B%E8%BD%A6/%E5%B7%B4%E5%93%88/U8/car1.jpg',
  })

  const U8 = {
    introduction: '为提高赛车竞争力，第一年的巴哈赛车采用的是一套全时四驱系统，前轮配有差速器，相较于巴哈大赛传统的后驱车具有更强的动力性和通过性。除整车的传动系统外，悬架系统、转向系统、轮边系统、制动系统及车身车架也由赛车工作室成员自主设计加工后装配。\n2021年，深圳技术大学巴哈车队更名为飓风赛车队，并于3月份顺利抢到车号U8。新一代赛车工作室成员加入后，在原有巴哈赛车的基础上对赛车进行进一步的优化。',
    parameter: '',
    competition: "2020.11.4，U8巴哈赛车在深圳技术大学进行线下发布。\n目前已经参与了2021届中国汽车工程学会举办的巴哈大赛，取得了全国三等奖的优异成绩，6项单项赛事中爬坡赛、操纵赛、单圈计时赛以及营销商业答辩斩获全国前十。\n正计划参加2022年8月份中国汽车工程学会举办的巴哈大赛。"
  }

  const [car, setCar] = useState(U8);
  const [topText, setTopText] = useState('U8巴哈赛车');
  const [topImg, setTopImg] = useState(U8_images.index);

  useEffect(() => {
    let carName = props.match.params.carName
    if (carName === 'U8') {
      setTopText('U8巴哈赛车')
      setTopImg(U8_images.index)
    } else if (carName === 'E23') {
      setTopText('E23方程式赛车')
      setTopImg(E23_images.index)
    }
  }, []);

  // 介绍row
  const IntroduactionRow = (e) => (
    <Row className="Car-IntroduactionRow" justify="center">
      <Col span={24} className="Car-IntroduactionRow-title">{e.title}</Col>
      <Col xs={22} sm={22} md={20} className="Car-IntroduactionRow-content">{e.content}</Col>
    </Row>
  )

  // 汽车数据
  const CarDataRow = () => {
    return (
      <Row className="CarDataRow" justify='center' align='middle' gutter={[40, 30]}>
        <Col xs={20} sm={20} md={10} style={{ display: 'flex', flexDirection: 'row', justifyContent: "center" }}>
          <img src={U8_images.car1} className="CarDataRow-img"></img>
        </Col>
        <Col xs={20} sm={20} md={10}>
          <Row justify='center' gutter={[0, 0]}>
            <Col xs={10} sm={8} md={10} >
              <p>车重(kg)         </p>
              <p>驱动形式     </p>
              <p>最高车速(km/h)     </p>
              <p>差速器数量      </p>
              <p>轴距(mm)        </p>
              <p>前轮距（mm)    </p>
              <p>后轮距(mm)      </p>
              <p>转弯半径(m)       </p>
              <p>轮跳范围(cm） </p>
              <p>零件安全系数    </p>
            </Col>
            <Col xs={8} sm={8} md={10} style={{ textAlign: "right" }}>
              <p>        174</p>
              <p>     四驱 </p>
              <p>    61 </p>
              <p>    2 </p>
              <p>       1390mm </p>
              <p>     1288 mm</p>
              <p>   1200mm </p>
              <p>    3.1m </p>
              <p>   45-53 mm  </p>
              <p>     >1.5  </p>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }

  return (
    <div>
      <TopImageShow src={topImg} text={topText} />
      <IntroduactionRow title="介绍" content={car.introduction} />
      <CarDataRow />
      <IntroduactionRow title="参加的赛事" content={car.competition} />
    </div>
  );
}
